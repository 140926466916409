import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "item"]
  static values = { index: Number }

  connect() {
    this.activeIndex = this.indexValue
    this.setActiveImage(this.activeIndex)
    this.setActiveItem(this.activeIndex)
  }

  setActiveImage(activeIndex) {
    this.imageTargets.forEach((image, index) => {
      if (index === activeIndex) {
        image.classList.remove("hidden")
        image.classList.add("active")
      } else {
        image.classList.add("hidden")
        image.classList.remove("active")
      }
    })
  }

  setActiveItem(activeIndex) {
    this.itemTargets.forEach((item, index) => {
      item.classList.remove("active")

      if (index === activeIndex) {
        this.itemTargets[index].classList.add("active")
      }
    })
  }

  change(event) {
    this.activeIndex = parseInt(event.currentTarget.dataset.sliderIndex)
    this.setActiveImage(this.activeIndex)
    this.setActiveItem(this.activeIndex)
  }
}
