import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "submenu"]

  toggle() {
    this.menuTarget.toggleAttribute("open")
    this.submenuTarget.toggleAttribute("open")
  }
}
