import Rails from "@rails/ujs"

// CSS
import "../website/css/website.css"

// Images
const importAll = (r) => r.keys().map(r)
importAll(require.context("../images", true, /\.(png|jpe?g|svg|ico)$/))

// Stimulus controllers
import "../website/controllers"

// Stimulus global controllers
import "../controllers"

// global JS
import "../global/javascript"

//Analytics
import "../components/analytics"

Rails.start()
