import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navbar", "section"]

  connect() {
    if (this.hasSectionTarget) {
      document.addEventListener("scroll", this.onScroll.bind(this))
      this.setNavigationColor(this.getCurrentSection())
    }
  }

  disconnect() {
    if (this.hasSectionTarget) {
      document.removeEventListener("scroll", this.onScroll.bind(this))
    }
  }

  onScroll() {
    this.setNavigationColor(this.getCurrentSection())
  }

  getCurrentSection() {
    const navbarHeight = this.navbarTarget.offsetHeight

    for (const section of this.sectionTargets) {
      const rect = section.getBoundingClientRect()
      const top = rect.top + window.scrollY
      const bottom = rect.bottom + window.scrollY

      if (top <= window.scrollY + navbarHeight && bottom >= window.scrollY + navbarHeight) {
        return section
      }
    }

    return null
  }

  setNavigationColor(section) {
    if (section) {
      const { background } = section.dataset
      this.element.dataset.mode = background
    } else {
      delete this.element.dataset.mode
    }
  }
}
