import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    exampleType: String
  }

  initialize() {
    this.baseUrl = "https://raw.githubusercontent.com/bump-sh/examples/main/"
  }

  setExampleUrl(event) {
    const type = event.currentTarget.dataset.exampleType
    this.inputTarget.value = this.exampleUrl(type)
  }

  exampleUrl(type) {
    return `${this.baseUrl}${type}_example.yml`
  }
}
