import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static values = {
    min: { type: Number, default: 26 },
    max: { type: Number, default: 0 },
  }

  connect() {
    this.scroll()
  }

  disconnect() {
    cancelAnimationFrame(this._animationFrameId)
  }

  updateRotation() {
    const rotation = `perspective(1000px) rotateX(${this.currentValue}deg)`
    this.element.style.transform = rotation
  }

  scroll() {
    const scrollPosition = window.scrollY
    const documentHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const scrollRange = documentHeight - windowHeight
    const scrollPercentage = scrollPosition / scrollRange

    const easing = 1 - Math.pow(1 - scrollPercentage, 3)
    const adjustedPercentage = this.minValue - easing * this.range * 4

    if (adjustedPercentage > this.maxValue) {
      this.currentValue = adjustedPercentage
    }

    requestAnimationFrame(() => {
      this.scroll()
    })
  }

  get currentValue() {
    return this._currentValue || this.minValue
  }

  set currentValue(value) {
    this._currentValue = value
    this.updateRotation()
  }

  get range() {
    return this.minValue - this.maxValue
  }
}
