import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loader", "buttonContent", "form"]

  onPostSuccess(event) {
    let [data,,] = event.detail

    this.buttonContentTarget.classList.add("hidden")
    this.loaderTarget.classList.remove("hidden")
    setTimeout(() => {
      this.loaderTarget.classList.remove("hidden")
      this.formTarget.innerHTML = `
        <p>${data.message}</p>
      `
    }, 2000)
  }

  onPostError(event) {
    let [,, xhr] = event.detail

    this.formTarget.innerHTML = `
      <p>${xhr.response}</p>
    `
  }
}
